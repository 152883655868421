import React, { FC } from 'react';
import heartIcon from '../../imgs/heart.png';
import iconDumbell from '../../imgs/icon_dumbell.png';
import iconExercise from '../../imgs/icon_exercise.png';
import iconJoints from '../../imgs/icon_joints.png';
import iconOld from '../../imgs/icon_old.png';
import iconPain from '../../imgs/icon_pain.png';
import iconRelax from '../../imgs/icon_relax.png';
import iconSun from '../../imgs/icon_sun.png';
import iconClock from '../../imgs/icon_clock.png';
const ResultInfo: FC = () => {
  return (
    <div className='result-info'>
      <div className='info-dialog'>
        <img src={heartIcon} className='Heart-icon' alt='heart-logo' />
        <h4 className='header'>There are 2 types of back pain. </h4>
        <p className='subheader'>
          They may cause similar symptoms, but they're very different.
          <sup>1</sup>
        </p>
        <div className='pills'>
          <div className='btn white'> Immune-related </div>
          <div className='btn'> Mechanical </div>
        </div>
      </div>
      <div className='info-graphic'>
        <div className='left-info'>
          <h3>IMMUNE-RELATED BACK PAIN</h3>
          <div className='info-container'>
            <div className='info-box white'>
              <img src={iconOld} className='icon-old' alt='icon-old' />
              <p>
                Characteristic<br/> onset at &lt;40<br/> years of age.<sup>1</sup>
              </p>
            </div>
            <div className='info-box white'>
              <img src={iconJoints} className='icon-join' alt='icon-join' />
              <p>
              Chronic back pain,<br/> stiffness, & changes<br/> in mobility lasting<br/> 3+ months.<sup>1,4</sup>
              </p>
            </div>
            <div className='info-box white'>
              <img src={iconSun} className='icon-sun' alt='icon-sun' />
              <p>
              Symptoms that are<br/> worse at night and<br/> in the morning.<sup>1,4</sup>
              </p>
            </div>
            <div className='info-box white'>
              <img src={iconDumbell} className='icon-dumbell' alt='icon-dumbell' />
              <p>
              Exercise can provide symptomatic relief.<sup>1</sup>
              </p>
            </div>
          </div>
        </div>
        <div className='right-info'>
          <h3>MECHANICAL BACK PAIN</h3>
          <div className='info-container'>
            <div className='info-box red'>
              <img src={iconClock} className='icon-clock' alt='icon-clock' />
              <p>
              Symptoms can <br/>appear at any age.<sup>2</sup>
              </p>
            </div>
            <div className='info-box red'>
              <img src={iconPain} className='icon-pain' alt='icon-pain' />
              <p>
              Pain can <br/>be acute.<sup>3</sup>
              </p>
            </div>
            <div className='info-box red'>
              <img src={iconRelax} className='icon-relax' alt='icon-relax' />
              <p>
              Rest and relaxation <br/>can help ease <br/>symptoms.<sup>1</sup>
              </p>
            </div>
            <div className='info-box red'>
              <img src={iconExercise} className='icon-ex' alt='icon-ex' />
              <p>
              Pain may worsen <br/>with exercise.<sup>1</sup>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultInfo;
