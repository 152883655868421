import React from 'react';
import emailIcon from '../../imgs/envelope.png';
import calendarIcon from '../../imgs/calendar.png';
interface IEmail {
  emailResult: string
}
const Email = ({ emailResult }: IEmail) => {
  return (
    <div className='email-section'>
      <div className="email-section-inner">
        <a href={'mailto:?subject=Monster Back Pain - Your responses&body=' + emailResult} className='btn email'>
          <img src={emailIcon} className='Email-icon' alt='email-logo'/> Email my results
        </a>
        <br />
        <small className='email-disclaimer'>
          Your email address will only be used for the purpose of delivering a copy of your results. 
          Your email address and quiz answers are not stored or collected for any other use.
        </small>
      </div>
      <div className="email-section-inner">
        <a href="https://www.hotdoc.com.au/" className='btn calendar' target={'_blank'} rel="noreferrer">
          <img src={calendarIcon} className='Calendar-icon' alt='calendar-logo'/> Book an appointment
        </a>
        <br />
        <small className='email-disclaimer'>
          This link will take you outside of <br className='hide-mobile' />
          monsterbackpain.com.au
        </small>
      </div>
  </div>
  );
};

export default Email;
