import React from 'react';

interface IQuestion {
  questionList: string[];
  currentStep: number;
}
const Questions = ({ questionList, currentStep }: IQuestion) => {
  return (
    <div>
      <div className='questions'>
        <p>{questionList[currentStep]}</p>
      </div>
    </div>
  );
};

export default Questions;
