import React from 'react';
import './App.scss';
import './scss/Colours.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Start from './components/Start/Start';
import Landing from './components/Landing/Landing';
import Form  from './components/Form/Form';
import SocialLanding  from './components/SocialLanding/SocialLanding';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-PQRBNWG'
}

TagManager.initialize(tagManagerArgs);
function App() {
  return (
    <Router>
      <div className='App'>
          <header className='App-header'>
            <Routes>
              <Route path='/' element={<Landing />} />
              <Route path='/start' element={<Start />} />
              <Route path='/form' element={<Form />} /> 
              <Route path='/social-landing' element={<SocialLanding />} />
            </Routes>
          </header>
      </div>
    </Router>
  );
}

export default App;
