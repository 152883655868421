interface IFormData {
    questionList: string[]
    info: string[]
    refs: string[]
  }
  
  export const formData: IFormData = {
    questionList: [
      'Did your back pain start when you were aged 40 or under?',
      'Did your back pain develop gradually?',
      'Does your back pain improve with exercise?',
      'When you rest do you find there is no improvement?',
      'Do you suffer from increased back pain and discomfort when immobile during sleep, and start to feel better once up and moving?'
    ],
    info: [
      'Symptoms of mechanical back pain can start at any age, <br class="br">while immune-related back pain has a characteristic onset at &lt;40 years of age.<sup>1,2</sup> ',
      'Symptoms of mechanical back pain are often sudden (acute), <br class="br"> while immune-related back pain is a chronic condition, resulting in pain,<br class="br"> stiffness and changes in mobility lasting three months or longer.<sup>1-3</sup>',
      'Mechanical back pain may worsen with exercise, while in <br class="br">some cases exercise can provide symptomatic relief to <br class="br">immune-related back pain.<sup>1</sup>',
      'Rest and relaxation can help to ease the symptoms <br class="br">of mechanical back pain, but this is not as true with <br class="br">immune-related back pain.<sup>1</sup>',
      'Symptoms of immune-related back pain can be worse <br class="br">at night or in the morning.<sup>1,2</sup>',
    ],
    refs: [
      'References: 1. Lassiter W, Allam A. Inflammatory Back Pain. StatPearls - NCBI Bookshelf, 2020. <a href=\'https://www.ncbi.nlm.nih.gov/books/NBK539753/?report=printable\' target=\'_blank\' rel=\'noreferrer\'>Available here</a>. Accessed February 2021. 2. Strand V, Singh JA. <em>Mayo Clin Proc</em> 2017;92(4):555–64.',
      'References: 1. Lassiter W, Allam A. Inflammatory Back Pain. StatPearls - NCBI Bookshelf, 2020. <a href=\'https://www.ncbi.nlm.nih.gov/books/NBK539753/?report=printable\' target=\'_blank\' rel=\'noreferrer\'>Available here</a>. Accessed February 2022. 2. 2. U.S Department of Health and Human Services. National Institutes of Health. Low Back Pain. <a href=\'https://www.ninds.nih.gov/sites/default/files/migrate-documents/low_back_pain_20-ns-5161_march_2020_508c.pdf\' target=\'_blank\' rel=\'noreferrer\'>Available here</a>. Accessed February 2022. 3. Weisman MH.<em>Rheum Dis Clin North Am</em> 2012;38(3):501–12.',
      'Reference: 1. Lassiter W, Allam A. Inflammatory Back Pain. StatPearls - NCBI Bookshelf, 2020.  <a href=\'https://www.ncbi.nlm.nih.gov/books/NBK539753/?report=printable\' target=\'_blank\' rel=\'noreferrer\'>Available here</a>. Accessed February 2022.',
      'References: 1. Lassiter W, Allam A. Inflammatory Back Pain. StatPearls - NCBI Bookshelf, 2020. <a href=\'https://www.ncbi.nlm.nih.gov/books/NBK539753/?report=printable\' target=\'_blank\' rel=\'noreferrer\'>Available here</a>. Accessed February 2021. 2. U.S Department of Health and Human Services. National Institutes of Health. Low Back Pain. <a href=\'https://www.ninds.nih.gov/sites/default/files/migrate-documents/low_back_pain_20-ns-5161_march_2020_508c.pdf\' target=\'_blank\' rel=\'noreferrer\'>Available here</a>. Accessed February 2021. 3. Weisman MH. <em>Rheum Dis Clin North Am</em> 2012;38(3):501–12.',
      'References: 1. Lassiter W, Allam A. Inflammatory Back Pain. StatPearls - NCBI Bookshelf, 2020. <a href=\'https://www.ncbi.nlm.nih.gov/books/NBK539753/?report=printable\' target=\'_blank\' rel=\'noreferrer\'>Available here</a>. Accessed February 2021. 2. Weisman MH. <em>Rheum Dis Clin North Am</em> 2012;38(3):501–12.',
    ]
  }