import React from 'react';
import Parser from 'html-react-parser';
import bg from '../../imgs/info-bg-mobile.png';
interface IInfo {
  infoText: string;
}
const Info = ({ infoText }: IInfo) => {
  return (
    <div className='info'>
      <img src={bg} className='mobile-bg' alt='mobile-bg' />
      <div className='info-container'>
        <h2>Why this matters <span className='question-icon'></span></h2>
        <p>{Parser(infoText)}</p>
      </div>
    </div>
  );
};

export default Info;
