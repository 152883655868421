import React from 'react';
import logo from '../../imgs/logo.svg';
import progress from '../../imgs/progress_icon.svg';
import ResultInfo from '../ResultInfo/ResultInfo';
import calendarIcon from '../../imgs/calendar.png';

const SocialLanding = () => {
  
  return (
    <div className="social-landing">
      <div className="container">
        <p className="summaryText">
          You answered some of our <span className="red">quiz questions</span>
          <br />
          in the past, indicating that
        </p>
        <div>
          <h2 className="resultText">
            you may have <br />
            <span className="red">
              immune-related <br />
              back pain.
            </span>
          </h2>
          <p className="suggestText">
            Put an end to starting your day with
            <span className="red"> monster back pain.</span>
          </p>
          <p className="suggestText">
            Book an appointment and talk to your doctor about this topic.
          </p>
        </div>

        <div className="email-section">
          <div className="email-section-inner">
            <a
              href="https://www.hotdoc.com.au/"
              className="btn calendar"
              target={"_blank"}
              rel="noreferrer"
            >
              <img
                src={calendarIcon}
                className="Calendar-icon"
                alt="calendar-logo"
              />{" "}
              Book an appointment
            </a>
            <br />
            <small className="email-disclaimer">
              This link will take you outside of <br className="hide-mobile" />
              monsterbackpain.com.au
            </small>
          </div>
        </div>

        <img src={progress} className="progress" alt="icon" />
        <div className="result-section">
          <img src={logo} className="App-logo" alt="logo" />
          <h3 className="result-header">
            If you answered <span className="red">YES</span> to the questions
            below, you may <br />
            have immune-related back pain
          </h3>

          <div className="answers-container">
            <div>
              <p>1. Did your back pain start when you were aged 40 or under?</p>
            </div>
            <div>
              <p>2. Did your back pain develop gradually?</p>
            </div>
            <div>
              <p>3. Does your back pain improve with exercise?</p>
            </div>
            <div>
              <p>4. When you rest do you find there is no improvement?</p>
            </div>
            <div>
              <p>
                5. Do you suffer from increased back pain and discomfort when
                immobile during sleep, and start to feel better once up and
                moving?
              </p>
            </div>
          </div>

          <h3 className="result-header">
            <span className="red">Book an appointment</span> using the button
            below to discuss <br />
            your back pain with your doctor.
          </h3>
          <a
            href="https://www.hotdoc.com.au/"
            className="btn calendar"
            target={"_blank"}
            rel="noreferrer"
          >
            <img
              src={calendarIcon}
              className="Calendar-icon"
              alt="calendar-logo"
            />{" "}
            Book an appointment
          </a>
          <small className="email-disclaimer">
            This link will take you outside of <br className="hide-mobile" />
            monsterbackpain.com.au
          </small>
        </div>

        <ResultInfo />

        <div className="reference">
          References: 1. Lassiter W, Allam A. Inflammatory Back Pain. StatPearls
          NCBI Bookshelf, 2020.{" "}
          <a
            href="https://www.ncbi.nlm.nih.gov/books/NBK539753/?report=printable"
            target="_blank"
            rel="noreferrer"
          >
            Available here
          </a>
          . Accessed February 2022. 2. Strand V, Singh JA.{" "}
          <em>Mayo Clin Proc</em> 2017:92(4):555-64. 3. U.S Department of Health
          and Human Services. National Institutes of Health. Low Back Pain.{" "}
          <a
            href="https://www.ninds.nih.gov/sites/default/files/migrate-documents/low_back_pain_20-ns-5161_march_2020_508c.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Available here
          </a>
          . Accessed February 2021. 4. Weisman MH.
          <em>Rheum Dis Clin North Am</em> 2012;38(3):501-12.
          <br />
          <br />
          Novartis Pharmaceuticals Australia Pty Limited ABN 18 004 244 160. 54
          Waterloo Road, Macquarie Park NSW 2113. Ph (02) 9805 3555.
          <br />
          February 2023. AU-20437. 2002895.
        </div>
      </div>
    </div>
  );
};

export default SocialLanding;
