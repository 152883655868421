import React, { FC, useState, FormEvent, useEffect } from "react";
import Parser from "html-react-parser";
import logo from "../../imgs/logo.svg";
import Questions from "../Questions/Questions";
import Info from "../Info/Info";
import Result from "../Result/Result";
import { FaCheck } from "react-icons/fa";
import { formData } from "./FormData";
import classnames from "classnames";
declare global {
  interface Window {
    dataLayer: any[];
  }
}

const Form: FC = () => {
  // TODO: Need to disable this line to prevent error.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [answer, updateAnswer] = useState<string>("");
  const [answerList, updateAnswerList] = useState<string[]>([]);
  const [yesAnswer, setYesAnswer] = useState<number>(0);
  const [step, setStep] = useState<number>(0);
  const { questionList, info, refs } = formData;

  useEffect(() => {
    const dataLayerResult = (): void => {
      let fullAnswers = "";
      [...answerList].forEach((answer, index): void => {
        fullAnswers += `Q${index + 1}_${answer}|`;
      });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "resultsViewed",
        fullAnswers: fullAnswers.slice(0, -1),
      });
    };

    if (answerList.length === questionList.length) {
      const answer = [...answerList].filter((answer) => {
        return answer === "Yes";
      }).length;
      setYesAnswer(answer);
      dataLayerResult();
    }
  }, [answerList, yesAnswer, questionList]);

  const handleSubmit = (e: FormEvent): void => {
    e.preventDefault();
    const answerEl = e.target as HTMLInputElement;
    answerEl.blur();
    const answer = answerEl.value;
    //overwrite the answer then a user navigate to previous question
    if (answerList[step]) {
      answerList[step] = answer;
      updateAnswer(answer);
    } else {
      updateAnswer("");
      updateAnswerList([...answerList, answer]);
      setStep(step + 1);
      document.body.focus();
    }
    dataLayerFormClick(step + 1, answer);
  };

  const dataLayerFormClick = (question: number, answer: string): void => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "questionResponse",
      question: question,
      answer: answer,
    });
  };

  const handleClick = (e: FormEvent<HTMLInputElement>): void => {
    handleSubmit(e);
  };

  return (
    <div
      className={classnames("form", {
        "show-result": answerList.length === questionList.length,
      })}
    >
      <div className="container">
        {answerList.length < questionList.length &&
          step < questionList.length && (
            <div className="form-header">
              <div className="form-area">
                <div className="navbar">
                  <img src={logo} className="App-logo" alt="logo" />
                  <nav className="menu-bar">
                    {questionList.map((question, index) => {
                      return (
                        <button
                          key={index}
                          className={classnames(
                            "menu",
                            { active: index === step },
                            { done: answerList[index] }
                          )}
                          onClick={() => {
                            if (answerList[index]) {
                              setStep(index);
                            } else if (index === answerList.length) {
                              setStep(index);
                            }
                          }}
                        >
                          {answerList[index] ? (
                            <FaCheck color="#D91F26" />
                          ) : (
                            index + 1
                          )}
                        </button>
                      );
                    })}
                    <button className="menu result">results</button>
                  </nav>
                </div>
                <div className="question-section">
                  <Questions currentStep={step} questionList={questionList} />
                  <div className="answer-options">
                    <div className="cta-container">
                      <div
                        className={classnames({
                          "glow-effect": answerList[step] === "Yes",
                        })}
                      ></div>
                      <input
                        type="button"
                        className={classnames("answer", {
                          selected: answerList[step] === "Yes",
                        })}
                        onClick={handleClick}
                        value="Yes"
                      />
                    </div>
                    <div className="cta-container">
                      <div
                        className={classnames({
                          "glow-effect": answerList[step] === "No",
                        })}
                      ></div>
                      <input
                        type="button"
                        className={classnames("answer", {
                          selected: answerList[step] === "No",
                        })}
                        onClick={handleClick}
                        value="No"
                      />
                    </div>
                  </div>
                </div>
                <Info infoText={info[step]} />
                <div className="reference">
                  {refs.map((ref, index) => {
                    if (index === step) {
                      return <p key={index}>{Parser(ref)}</p>;
                    } else {
                      return <p key={index}></p>;
                    }
                  })}
                </div>
              </div>
            </div>
          )}
        {answerList.length === questionList.length &&
          step >= questionList.length && (
            <Result
              answers={answerList}
              questions={questionList}
              yesAnswers={yesAnswer}
            />
          )}
      </div>
    </div>
  );
};

export default Form;
