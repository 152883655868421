import React, { FC } from 'react';
import logo from '../../imgs/logo.svg';
import { Link } from 'react-router-dom';
const Landing: FC = () => {
  return (
    <div className='landing'>
      <div className='container'>
      <img src={logo} className='App-logo' alt='logo' />
      <p className='sub-title'>
        Take a quick quiz to possibly find an <br />
        answer to your unexplained back pain.
      </p>
      <Link className='btn' to="/start">TAKE THE QUIZ</Link>
      <p className='reference'>
      Novartis Pharmaceuticals Australia Pty Limited ABN 18 004 244 160. 
      54 Waterloo Road, Macquarie Park NSW 2113. 
      Ph (02) 9805 3555. May 2022. AU-20437. 2001906.
      </p>
      </div>

    </div>
  );
};

export default Landing;
