import React, { FC } from 'react';
import { Link } from 'react-router-dom';
const Start: FC = () => {
  return (
    <div className='start'>
      <div className='disclaimer-top'>
        <p>
          This quiz may help identify people with immune-related back pain.*
        </p>
      </div>
      <h3 className='sub-header'>
        Before we begin the quiz, let's talk about what it's about!
      </h3>
      <h2 className='title'>Not all back pain is the same.</h2>

      <div className='info-container'>
        <div className='main-info'>
          <p className='info-header'>
            In fact, there are 2 main forms of back pain:
          </p>
          <div className='list-factor'>
            <ul>
              <li>
                <span>1.</span> Mechanical
              </li>
              <li>
                <span>2.</span> Immune-related
              </li>
            </ul>
          </div>
          <p className='info-explain'>
            They may cause similar symptoms, but they're very different.
            <sup>1</sup> <br />
            <br />
            If you have experienced back pain for more than three months, <br />
            there's a chance it could be immune-related.
          </p>
          <div className='cta-container'>
            <div className='glow-effect'></div>
            <Link className='btn' to='/form'>
              LET'S GO
            </Link>
          </div>
        </div>
        <div className='disclaimer-bottom'>
          Early identification and management of immune-related back pain may
          help prevent further disease progression and provide better long-term
          outcomes.<sup>2</sup>
        </div>
      </div>

      <div className='footer'>
        <p className='footer-text'>
          *This tool was adapted from the ASAS (the Assessment of
          SpondyloArthritis international Society) criteria for identifying
          immune-related back pain. It is not meant to diagnose people with
          immune-related back pain or diseases associated with immune-related
          back pain.<sup>3</sup> Please use it to have a more meaningful
          discussion with your doctor. We do not store or collect the
          information filled out in this quiz.
        </p>

        <p className='reference'>
          References: 1. Lassiter W, Allam A. Inflammatory Back Pain. StatPearls
          - NCBI Bookshelf, 2020.{' '}
          <a
            href='https://www.ncbi.nlm.nih.gov/books/NBK539753/?report=printable'
            target='_blank'
            rel='noreferrer'
          >
            Available here
          </a>
          . Accessed February 2021.
          <br />
          2. Russell MD <em>et al. Rheumatology (Oxford)</em> 2022; 61(2):
          734-42. 3. Sieper J <em>et al. Ann Rheum Dis.</em> 2009;68:784-88.
        </p>
      </div>
    </div>
  );
};

export default Start;
