import React from 'react';
import logo from '../../imgs/logo.svg';
import progress from '../../imgs/progress_icon.svg';
import Email from '../Email/Email';
import ResultInfo from '../ResultInfo/ResultInfo';
interface IResult {
  answers: string[];
  questions: string[];
  yesAnswers: number;
}
const Result = ({ answers, questions, yesAnswers }: IResult) => {
  let emailResult = `You answered YES to ${yesAnswers} out of 5 questions, ${yesAnswers > 3 ? 'which indicates you may have immune-related back pain. You should consider talking to your doctor about this topic.' : 'Based on your answers, it’s unlikely you have immune-related back pain. However, you may still wish to discuss this possibility with your doctor.%0d%0a%0d%0a'}`;
  emailResult += '%0d%0a-----------------------------------------%0d%0a';
  const result = answers.map((answer, k) => {
    emailResult += `${questions[k]} [${answer}]%0d%0a`;
    return (
      <div key={k}>
        <p>
          {k+1}{'. '}{questions[k]}{' '}
          <span className='red'>
            <b>{answer}</b>
          </span>
        </p>
      </div>
    );
  });
  emailResult += '-----------------------------------------%0d%0a%0d%0a';

  return (
    <div className='result'>
      <p className='summaryText'>
        You answered <span className='red'>yes</span>
        <br />
        to {yesAnswers} out of 5 questions, {yesAnswers > 3 ? 'which indicates' : 'based on your answers,'}
      </p>
      {yesAnswers > 3 ? (
        <div>
          <h2 className='resultText'>
            you may have <br />
            <span className='red'>
              immune-related <br />
              back pain.
            </span>
          </h2>
          <p className='suggestText'>
            You should consider talking <br/>to your doctor about this topic.
          </p>
        </div>
      ) : (
        <div>
          <h2 className='resultText'>
            it's unlikely you<br/> have immune-related <br/>back pain
          </h2>
          <p className='suggestText'>
            However, you may still wish to discuss <br/>this possibility with your doctor. 
          </p>
        </div>

      )}

      <Email emailResult={emailResult} />
      <img src={progress} className='progress' alt='icon'/>
      <div className='result-section'>
        <img src={logo} className='App-logo' alt='logo' />
        <h3 className='result-header red'>YOUR RESPONSES</h3>

        <div className='answers-container'>{result}</div>

        <Email emailResult={emailResult} />
      </div>

      <ResultInfo/>

      <div className='reference'>
        References: 1. Lassiter W, Allam A. Inflammatory Back Pain. StatPearls
        NCBI Bookshelf, 2020. <a href='https://www.ncbi.nlm.nih.gov/books/NBK539753/?report=printable' target='_blank' rel='noreferrer'>Available here</a>. Accessed February 2022. 2. Strand
        V, Singh JA. <em>Mayo Clin Proc</em> 2017:92(4):555-64. 3. U.S
        Department of Health and Human Services. National Institutes of Health.
        Low Back Pain. <a href='https://www.ninds.nih.gov/sites/default/files/migrate-documents/low_back_pain_20-ns-5161_march_2020_508c.pdf' target='_blank' rel='noreferrer'>Available here</a>. Accessed February 2021. 4. Weisman MH.
        <em>Rheum Dis Clin North Am</em> 2012;38(3):501-12.
        <br /><br />
        Novartis Pharmaceuticals Australia Pty Limited ABN 18 004 244 160. 54 Waterloo Road, Macquarie Park NSW 2113. Ph (02) 9805 3555.<br />
        February 2023. AU-20437. 2002895.
      </div>
    </div>
  );
};

export default Result;
